import React, { useRef } from 'react'
import Base from '../../Base'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from "uuid";
import { useContext } from 'react';
import { globalContext } from '../../../context/GlobalContext';

const SyncYoutube = () => {
    let navigate = useNavigate();
    const { setUserDetail } = useContext(globalContext);
    const nameRef = useRef("")
    const createRoom = () => {
        setUserDetail({
            name: nameRef.current.value
        })
        navigate("/ytroom/" + uuidv4());
    }
    return (
        <Base documentTitle="Create Room">
            <form className="g-3">
                <div class="row mb-3">
                    <label for="staticEmail2" className="form-label">Your Name: </label>
                    <input type="text" className="form-control" id="staticEmail2" placeholder='Enter Your Name' ref={nameRef} />
                    <button onClick={createRoom} className='btn btn-primary mb-3'>Create Room</button>
                </div>
            </form>

        </Base>
    )
}

export default SyncYoutube