import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import { globalContext } from "../../context/GlobalContext";

const Login = () => {
    const { isLoggedIn } = useContext(globalContext)
    let navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn) navigate('/')
        document.title = "Login - Tools"
    }, []);
    const { setIsLoggedIn, setAuthUser } = useContext(globalContext);


    const loginSubmit = async (e) => {
        e.preventDefault();
        let username = ""
        let password = ""
        username = e.target[0].value
        password = e.target[1].value
        await axios.post(`${process.env.REACT_APP_BACKEND}/auth/login`, { username: username, password: password })
            .then(({ data }) => {
                if (data.status === "error") {
                    toast.error(data.msg);
                    setIsLoggedIn(false);
                    try {
                        localStorage.removeItem('token');
                        localStorage.removeItem('refreshtoken');
                        localStorage.removeItem('name');
                    } catch (err) {
                        // 
                    }
                } else if (data.status === "success") {
                    toast.success(data.msg)
                    localStorage.setItem('token', data.token);
                    localStorage.setItem("refreshtoken", data.refreshToken);
                    localStorage.setItem('name', data.user.firstname);
                    setAuthUser({
                        id: data.user.id,
                        firstname: data.user.firstname || "",
                        lastname: data.user.lastname || "",
                        username: data.user.username || "",
                        email: data.user.email || "",
                        role: data.user.role || 0
                    })
                    setIsLoggedIn(true);
                    navigate('/')
                }
            })
            .catch((err) => {
                setIsLoggedIn(false);
                setAuthUser({
                    id: "",
                    firstname: "",
                    lastname: "",
                    username: "",
                    email: "",
                    role: 0
                })
                toast.error(err);
            })

    }

    return (
        <div className="overflow-x-hidden">
            <div className="fixed bottom-0 left-0 right-0 z-40 px-4 py-3 text-center text-white bg-gray-800">
                Designed & Maintained By <a className="text-gray-200 underline" href="https://namankhare.com">Naman</a> at <a className="text-gray-200 underline" href="https://haxworld.net">HaxWorld Easytech Sol Pvt. Ltd.</a>
            </div>
            <section className="flex flex-col md:flex-row h-screen items-center">
                <div className="bg-indigo-600 hidden md:block w-full md:w-1/2 xl:w-2/3 h-screen">
                    <img
                        src="https://source.unsplash.com/user/wsanter"
                        alt=""
                        className="w-full h-full object-cover"
                    />
                </div>

                <div
                    className="bg-white w-full md:max-w-md lg:max-w-full md:mx-auto md:mx-0 md:w-1/2 xl:w-1/3 h-screen px-6 lg:px-16 xl:px-12
        flex items-center justify-center"
                >

                    <div className="w-full h-100 grid content-center">
                        <h1 className="text-xl md:text-2xl font-bold leading-tight mt-12 text-black">
                            Log in to your account
                        </h1>

                        <form className="mt-6" onSubmit={loginSubmit}>
                            <div>
                                <label className="block text-gray-700">Email Address / Username</label>
                                <input
                                    type="text"
                                    name="username"
                                    id="username"
                                    placeholder="Enter Email Address or Username"
                                    className="w-full  text-black px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                                    autoFocus
                                    required
                                />
                            </div>

                            <div className="mt-4">
                                <label className="block text-gray-700">Password</label>
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    placeholder="Enter Password"
                                    minLength="6"
                                    className="w-full  text-black px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500
                focus:bg-white focus:outline-none"
                                    required
                                />
                            </div>

                            <button
                                type="submit"
                                className="w-full block bg-indigo-500 hover:bg-indigo-400 focus:bg-indigo-400 text-white font-semibold rounded-lg
              px-4 py-3 mt-6"
                            >
                                Log In
                            </button>
                        </form>
                        <hr class="h-px bg-gray-200 border-0 dark:bg-gray-700" />
                        <button
                            type="button"
                            className="w-full block bg-indigo-500 hover:bg-indigo-400 focus:bg-indigo-400 text-white font-semibold rounded-lg
              px-4 py-3"
                            onClick={() => { navigate('/signup') }}
                        >
                            Signup!
                        </button>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </div>
    );
};

export default Login;
