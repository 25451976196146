import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
// import ColorMode from '../components/ColorMode'
import { withRouter } from '../hooks/withRouter'
import Logo from '../assets/logos/logo.svg'
import { globalContext } from '../context/GlobalContext';
import '../assets/css/mode.css'
import { ToastContainer, toast } from 'react-toastify';

const currentTab = (location, path) => {
    if (location.pathname === path) {
        return { color: "var(--text-color)", fontWeight: "bold" }
    } else {
        return { color: "var(--text-color)" }
    }
}

const Menu = ({ location }) => {
    let navigate = useNavigate();
    const { isLoggedIn, authUser, setIsLoggedIn, setAuthUser } = useContext(globalContext);
    const { theme, setTheme } = useContext(globalContext);
    const ColorMode = () => {
        const updateChanges = (mode) => {
            if (localStorage) {
                localStorage.setItem("theme", mode);
            }
            setTheme(mode);
        }
        return (
            <button
                id="darkMode"
                onClick={() => (theme === 'light' ? updateChanges('dark') : updateChanges('light'))}
            ></button>
        )
    }

    const Signout = () => {
        setIsLoggedIn(false);
        setAuthUser({
            id: "",
            firstname: "",
            lastname: "",
            username: "",
            email: "",
            role: 0,
        });
        localStorage.removeItem('token')
        localStorage.removeItem('name')
        localStorage.removeItem('refreshtoken')
        toast.success("You have been logged out!")
    }

    return (
        <nav className="navbar navbar-expand-lg" style={{ borderBottom: "1px solid rgb(224, 224, 224)", height: '64px' }}>
            <div className="container-fluid">
                <button className={`navbar-toggler ${theme === 'light' ? "bg-light" : "bg-dark text-white"}`} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fas fa-bars"></i>
                </button>

                <span className="navbar-brand my-auto mx-5" >
                    <Link className="nav-link" to="/"><img src={Logo} style={theme === "light" ? { filter: 'invert(1)' } : { filter: 'invert(0)' }} height="30px" alt="HaxWorld Logo" className="logo" />
                    </Link>
                </span>
                <div className={`collapse navbar-collapse ${theme === 'light' ? "bg-light" : "bg-black"}`} id="navbarSupportedContent">

                    <ul className="navbar-nav text-center" style={{}}>
                        <li className="nav-item">
                            <Link style={currentTab(location, "/")} className="nav-link" to="/">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link style={currentTab(location, "/mergepdf")} className="nav-link" to="/mergepdf">Merge PDF</Link>
                        </li>
                        <li className="nav-item">
                            <Link style={currentTab(location, "/compresspdf")} className="nav-link" to="/compresspdf">Compress PDF</Link>
                        </li>
                        <li className="nav-item">
                            <Link style={currentTab(location, "/editpdf")} className="nav-link" to="/editpdf">View PDF</Link>
                        </li>
                        <li className="nav-item">
                            <Link style={currentTab(location, "/syncyt")} className="nav-link" to="/syncyt">Sync YouTube</Link>
                        </li>
                        <li className="nav-item d-flex d-sm-none">
                            <ColorMode />
                            <span > {isLoggedIn ? "Welcome, " + authUser.firstname : <button onClick={() => { navigate("/login") }} className='btn btn-outline-primary'>Login</button>}</span>
                            <i class="fas fa-sign-out-alt"></i>
                        </li>

                    </ul>
                </div>
                <div className="d-flex align-items-center mx-5 d-none d-sm-flex">
                    <span> {isLoggedIn ? `Welcome, ${authUser.firstname}` : <button onClick={() => { navigate("/login") }} className='btn btn-outline-primary mx-2'>Login</button>}</span>
                    {
                        isLoggedIn && <>
                            <span className='cursor-pointer mx-2' onClick={Signout}> <i alt="Signout" class="fas fa-sign-out-alt"></i></span>

                        </>
                    }
                    <ColorMode />
                </div>

            </div>
            <ToastContainer />
        </nav>
    )
}

export default withRouter(Menu)
